import { type MetaFunction } from '@remix-run/node';

import { SignIn } from '@clerk/remix';


export const meta: MetaFunction = () => {
  return [
    { title: 'Login | rythm health' },
  ];
};


export default function LoginPage() {
  return (
    <SignIn />
  );
}
